import React from "react"
import "../styles/trampoline.scss"

export default function Trampoline() {
  return (
    <div className="trampoline">
      <div className="trampoline--sky">
        <div className="trampoline--sky__cloud">
          <span className="cloud-circ"></span>
          <span className="cloud-circ"></span>
          <span className="cloud-circ"></span>
          <span className="cloud-circ"></span>
        </div>
        <div className="trampoline--sky__sun"></div>
      </div>
      <div className="trampoline--fence"></div>
      <div className="trampoline--tree"></div>
      <div className="trampoline--jumper"></div>
    </div>
  )
}
